/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { currency } from '@launchpad/util/NumberHelper';
import {
  LoadingOverlay,
  ModalDialog
} from '../../../../../../@launchpad/index';
import { date } from '../../../../../../@launchpad/util/DateHelper';
import AccountsModel from '../../models/AccountsModel';

const AccountBasicInfo = props => {
  const [account, setAccount] = useState(props.account);
  const [isLoadingClose, setIsLoadingClose] = useState(false);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState(false);
  const [newStatus, setNewStatus] = useState();

  useEffect(() => {
    setAccount(props.account);
  }, [props.account]);

  function changeAccountStatus() {
    const { id: accountId } = props.match.params;
    new AccountsModel()
      .changeAccountStatus(accountId, newStatus)
      .then(() => {
        props.onRefresh();
      })
      .catch(error => {
        console.log(error);
      });
  }

  let status = 'ACTIVE';
  switch (account.status) {
    case 'pending_activation':
      status = 'PENDING ACTIVATION';
      break;
    case 'active':
    case 'ok':
      status = 'ACTIVE';
      break;
    case 'locked':
      status = 'LOCKED';
      break;
    case 'stolen':
      status = 'STOLEN';
      break;
    default:
      status = account.status ? account.status.toUpperCase() : 'UNKNOWN';
  }

  if (account.locked) {
    status = 'LOCKED';
  }

  return (
    <div>
      <div className="row">
        {isLoadingClose ||
        !account ||
        (account && Object.keys(account).length === 0) ? (
          <LoadingOverlay />
        ) : (
          <div className="col-xl-12">
            <div className="element-with-blur">
              <div className="dark-blur" />
              <div className="box-header marginless">
                <h2>
                  <i className="fa fa-address-card" /> Account Summary
                </h2>
              </div>

              <div className="box-body text-white padded">
                <div className="row">
                  <div className="col-sm-6">
                    <dl>
                      <dt>
                        <h3>Balance:</h3>
                      </dt>
                      <dd>
                        <h3>
                          {currency(account.availableBalance, account.currency)}
                        </h3>
                      </dd>
                      <dt>Name:</dt>
                      <dd>{account.name}</dd>
                      <dt>Account number:</dt>
                      <dd>{account.accountNumber}</dd>
                      <dt>IBAN:</dt>
                      <dd>{account.iban}</dd>
                      <dt>Sort code:</dt>
                      <dd>{account.sortCode}</dd>
                      <dt>Swift Bic:</dt>
                      <dd>{account.swiftBic}</dd>
                      <dt>Currency:</dt>
                      <dd>{account.currency}</dd>
                      <dt>Balance created:</dt>
                      <dd>{date(account.created, 'D / MMMM / Y')}</dd>
                      <dt>Status:</dt>
                      <dd>{status}</dd>
                    </dl>
                  </div>
                  <div className="col-sm-6">
                    <dl>
                      <dt>Change IBAN status:</dt>
                      <dd>
                        <select
                          className="option-wrapper"
                          onChange={event => {
                            setShowChangeStatusModal(true);
                            setNewStatus(event.target.value);
                          }}
                          value={account.status}
                        >
                          <option disabled value="">
                            -- select an option --
                          </option>
                          <option value="active">Active</option>
                          <option value="closed">Closed</option>
                          <option value="locked">Locked</option>
                        </select>
                        <ModalDialog
                          show={showChangeStatusModal}
                          onClose={() => setShowChangeStatusModal(false)}
                        >
                          <p>
                            Are you sure you want to change IBAN status, action
                            may be irreversible?
                          </p>
                          <div className="react-modal-footer">
                            <a
                              href="javascript:void(0)"
                              className="button"
                              onClick={() => {
                                setNewStatus(null);
                                setShowChangeStatusModal(false);
                              }}
                            >
                              No
                            </a>
                            <a
                              href="javascript:void(0)"
                              className="button-danger"
                              onClick={() => {
                                setNewStatus(null);
                                setShowChangeStatusModal(false);
                                changeAccountStatus();
                              }}
                            >
                              Yes
                            </a>
                          </div>
                        </ModalDialog>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: '20px' }}>
              <div className="col-xl-12">
                {/* <LastFiveTransactions accountId={this.props.account.id} /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(AccountBasicInfo);
